import React from 'react'

import {
  Paragraph,
  SmallText,
  TaskList,
  TaskListItem,
  Text
} from '@te-digi/styleguide'

const TaskListExample = () => (
  <TaskList>
    <TaskListItem status="complete">
      <Paragraph noMargin>
        <Text fontWeight="semi-bold">Mitä osaan tehdä</Text>
      </Paragraph>
      <SmallText noMargin>6 ammattia, 38 osaamista</SmallText>
    </TaskListItem>
    <TaskListItem>
      <Paragraph noMargin>
        <Text fontWeight="semi-bold">Kielitaito</Text>
      </Paragraph>
      <SmallText noMargin>suomi, englanti, ruotsi, saksa</SmallText>
    </TaskListItem>
  </TaskList>
)

export { TaskListExample }
