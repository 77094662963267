import React from 'react'

import { Paragraph, TaskList, TaskListItem, Text } from '@te-digi/styleguide'

const TaskListExample2 = () => (
  <TaskList>
    <TaskListItem>
      <Paragraph noMargin>
        <Text>Työnhakuprofiilisi otsikko</Text>
      </Paragraph>
    </TaskListItem>
    <TaskListItem status="complete">
      <Paragraph noMargin>
        <Text>Esittely</Text>
      </Paragraph>
    </TaskListItem>
    <TaskListItem status="complete">
      <Paragraph noMargin>
        <Text>Työnhakualue</Text>
      </Paragraph>
    </TaskListItem>
    <TaskListItem status="error">
      <Paragraph noMargin>
        <Text>Työn aloitusajankohta</Text>
      </Paragraph>
    </TaskListItem>
    <TaskListItem status="complete">
      <Paragraph noMargin>
        <Text>Linkit</Text>
      </Paragraph>
    </TaskListItem>
  </TaskList>
)

export { TaskListExample2 }
