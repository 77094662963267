import React from 'react'

import { Paragraph, TaskList, TaskListItem } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

import { TaskListExample } from '../../examples/react/TaskListExample'
import { TaskListExample2 } from '../../examples/react/TaskListExample2'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="TaskList"
    components={[{ component: TaskList }, { component: TaskListItem }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Playground>{TaskListExample()}</Playground>
      <Playground>{TaskListExample2()}</Playground>
    </Section>

    <Section title="Saavutettavuus">
      <Paragraph>Komponentti tuottaa semanttisesti listan.</Paragraph>
    </Section>
  </Content>
)

export default Page
